var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['add-new-investor', _vm.$route.name == 'newInvestorFormFinish' ? 'margin-top' : 0]
  }, [_vm.$route.name != 'newInvestorFormFinish' ? _c('div', {
    staticClass: "add-new-investor__actions"
  }, [_c('GenproxButton', {
    staticClass: "add-new-investor__back-btn outline gn-secondary",
    attrs: {
      "outline": true,
      "icon": _vm.ChevronLeftIcon
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")]), _c('portal-target', {
    attrs: {
      "name": "actions"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "add-new-investor__box"
  }, [_c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }