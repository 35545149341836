












import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import ChevronLeftIcon from '@/assets/icons/heroicons/ChevronLeftIcon.vue';

@Component({
  components: { SygniRectButton, GenproxButton, ChevronLeftIcon }
})
export default class MarketplaceModule extends Vue {
  ChevronLeftIcon = ChevronLeftIcon;

  async beforeMount() {
    try {
      this.$store.commit('investors/setPropagate', false);
      await this.$store.dispatch('campaigns/getLeadForm');
    } catch(e) {
      e;
    }
  }
}
